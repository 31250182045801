<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  components: {
    
  },
};
</script>
<style>
#app {
  font-family: "Comfortaa", cursive;
}

html {
  scroll-behavior: smooth;
}
.main-gradient {
  background: #ff5f6d; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ffc371,
    #ff5f6d
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ffc371,
    #ff5f6d
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.bc {
  background-color: #00000080;
}
</style>
