<template>
  <section :class="[width, bgColor]" class="mx-auto py-4 sm:py-8 md:py-20">
    <slot />
  </section>
</template>

<script>
export default {
  name: "AppSection",
  props: {
    width: {
      type: String,
      required: false,
      default: "w-[90%] md:w-[85%]",
    },
    bgColor: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped></style>
