import { createApp } from "vue";
import App from "./App.vue";
import "./index.css";
import AppSection from "./components/Layout/AppSection.vue";
import AppLayout from "./Views/Layout/AppLayout.vue";
import router from "./router/index.js";
import VueNumber from "vue-number-animation";
import AOS from "aos";
import { useAccordion } from "vue3-rich-accordion";
import "vue3-rich-accordion/accordion-library-styles.css";

import "aos/dist/aos.css";
const app = createApp(App);
app.use(AOS.init());
app.component("AppSection", AppSection);
app.component("AppLayout", AppLayout);
app.use(useAccordion);
app.use(router);
app.use(VueNumber);
app.mount("#app");
