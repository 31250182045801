import { createRouter, createWebHistory } from 'vue-router';

const HomePage = () => import(/* webpackChunkName: "home" */ '../Views/Home.vue');
const Contact = () => import(/* webpackChunkName: "contact" */ '../Views/ContactUs.vue');
const AboutUS = () => import(/* webpackChunkName: "aboutus" */ '../Views/AboutUS.vue');
const Services = () => import(/* webpackChunkName: "services" */ '../Views/Services.vue');
const Technologies = () => import(/* webpackChunkName: "technologies" */ '../Views/Technologies.vue');
const OurProducts = () => import(/* webpackChunkName: "ourproducts" */ '../Views/OurProducts.vue');
const DevelopmentService = () => import(/* webpackChunkName: "development" */ '../Views/ServicePages/DevelopmentService.vue');
const DesignService = () => import(/* webpackChunkName: "design" */ '../Views/ServicePages/DesignService.vue');
const DevOpsService = () => import(/* webpackChunkName: "devops" */ '../Views/ServicePages/DevOpsService.vue');
const ArtificialIntelligenceService = () => import(/* webpackChunkName: "ai" */ '../Views/ServicePages/ArtificialIntelligenceService.vue');
const QualityEngineeringService = () => import(/* webpackChunkName: "quality" */ '../Views/ServicePages/QualityEngineeringService.vue');
const CloudComputingService = () => import(/* webpackChunkName: "cloud" */ '../Views/ServicePages/CloudComputingService.vue');
const NotFound = () => import(/* webpackChunkName: "notfound" */ '../Views/NotFound.vue');
const PrivacyPolicy = () => import(/* webpackChunkName: "privacy" */ '../Views/PrivacyPolicy.vue');

export const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
    meta: {
      title: 'Top Software Development Companies in New York | Expert Solutions & Services',
      description: 'Discover Algoson, one of the leading software development companies in New York, specializing in innovative solutions tailored to your business needs.',
      keywords: 'leading software development company, customized software product, software development company, custom software development, software development company near me, startup software development, viewJs, reactjs, java software developers near me, Algoson software services in Switzerland, best software development company websites, website development company, agile development services, certified java developer',
    },
  },
  {
    path: '/aboutus',
    name: 'AboutUS',
    component: AboutUS,
    meta: {
      title: 'About Algoson | Leading Software Development Company in Texas',
      description: 'Explore Algoson, a premier software development company in Texas, dedicated to delivering customized software solutions that drive innovation and efficiency',
      keywords: 'local software developers, custom software development company, custom software development services, custom software development firms, application development companies, web development company, Algoson software services in Canada, custom web application development, python web development services, agile software development and devops, certified python and javascript  developer',
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      title: 'Contact Algoson | Get in Touch for Expert Software Solutions',
      description: 'Reach out to Algoson! Whether you require professional software assistance or have inquiries regarding our services',
      keywords: 'it software development company, best software developers near me, custom software development near me, hire best viewJs and reactjs expert, hire Algoson for best service in USA, Canada, France, Portugal, Italy, Switzerland and Germany, agile software development services, custom designed software company in India, app development websites',
    },
  },
  {
    path: '/services',
    name: 'Services',
    component: Services,
    meta: {
      title: 'Best Web Development and web designing Agency in Texas | Algoson',
      description: `At Algoson, you can find the best web design and development services in Texas. Our knowledgeable staff creates creative, approachable websites that are customized to your company's requirements. Boost your web visibility right now!`,
      keywords: 'low cost software development, software development, software development services, software design company, software product development company, Algoson software services in  Portugal, Algoson software services in USA, web application development company, java  and web development, web software development services, certified viewJs and reactjs developer',
    },
  },
  {
    path: '/development',
    name: 'DevelopmentService',
    component: DevelopmentService,
    meta: {
      title: 'Web Development Company in Newyork | Algoson Software',
      description: 'Learn about Algoson, a top web development firm in New York dedicated to creating creative and intuitive websites.',
      keywords: 'building website for business, web development, software development near me, web application development, custom software for local business, full stack web development service, Algoson software services in Germany, IT applications development, website and application development company',
    },
  },
  {
    path: '/design',
    name: 'DesignService',
    component: DesignService,
    meta: {
      title: 'Best Web designing Company in Florida | Algoson',
      description: `Searching for a leading Florida web design company? Algoson specializes in designing gorgeous, navigable websites that strengthen your company's image and provide outcomes. Change your web presence right now `,
      keywords: 'software designers near me, software design and development company, web and mobile application development services, javascript web programming, Algoson software services in France, best technologies for web development, build custom website, website design and development company',
    },
  },
  {
    path: '/quality-engineering',
    name: 'QualityEngineeringService',
    component: QualityEngineeringService,
    meta: {
      title: 'Quality Engineering Services in New york | Algoson Software',
      description: `Explore Algoson's Quality Engineering Services in New York, where we ensure your software meets the highest standards of quality and performance`,
      keywords: 'new software development technologies, backend technologies for web development, build web application, website design and development services, custom software developer, agile development technologies',
    },
  },
  {
    path: '/dev-operations',
    name: 'DevOpsService',
    component: DevOpsService,
    meta: {
      title: 'DevOps Services in Georgia | Algoson - Streamlined Solutions for Your Business',
      description: 'Learn about the DevOps services offered by Algoson in Georgia, which are intended to improve teamwork and optimize your development procedures.',
      keywords: 'software development services near me, startup software development company, web development services company, software application development company, website and app development, best software development services',
    },
  },
  {
    path: '/artificial-intelligence',
    name: 'ArtificialIntelligenceService',
    component: ArtificialIntelligenceService,
    meta: {
      title: 'About Algoson | Pioneers in Artificial Intelligence Solutions in Toronto',
      description: 'Learn about Algoson, a leader in artificial intelligence solutions in Toronto. Our dedicated team specializes in AI-driven technologies that transform businesses and enhance operational efficiency.',
      keywords: 'web application company, custom web application development services, software product development service, artificial intelligence ai, Algoson software services in Milan, high quality software development, ai machine learning technologies for software development, certified machine learning team, certified AI professional',
    },
  },
  {
    path: '/cloud-computing',
    name: 'CloudComputingService',
    component: CloudComputingService,
    meta: {
      title: 'Top Cloud Computing Services in Toronto | Algoson',
      description: `Learn more about Algoson's superior cloud computing services in Toronto. Our knowledgeable solutions provide efficiency, security, and scalability while being customized to match your company's demands.`,
      keywords: 'backend development services, AI and machine learning platform, custom software application development services, application software engineer, product and software development',
    },
  },
  {
    path: '/technologies',
    name: 'Technologies',
    component: Technologies,
    meta: {
      title: 'Technologies at Algoson | IT Experts in Toronto Delivering Innovative Solutions',
      description: 'Discover the cutting-edge technological solutions offered by Algoson, supported by Toronto top IT professionals.',
      keywords: 'custom application development, customer software development, best software development, web application development services, hire certified java experts, latest technologies in software development, trending technologies in software development, web development technologies',
    },
  },
  {
    path: '/products',
    name: 'products',
    component: OurProducts,
    meta: {
      title: 'Best Solutions For Buying and Selling Real estate | Algoson  ',
      description: 'Check out the extensive real estate marketplace on Algoson.com. Find tools, advice from experts, and listings to help you easily navigate the real estate market. Now is the time to set out on your quest!',
      keywords: 'Software solution company, customized software solution',
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: PrivacyPolicy,
    meta: {
      title: 'Privacy Policy',
      description: 'Privacy policy',
      keywords: 'Software solution company, customized software solution',
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
];

// const router = createRouter({
//   history: createMemoryHistory(),
//   routes,
// });

let router;

if (typeof window !== "undefined") {
    router = createRouter({
        history: createWebHistory(),
        scrollBehavior(to) {
            if (to.hash) {
                return { el: to.hash, behavior: "smooth", offset: { x: 0, y: -250 } };
            }
            return { top: 0, behavior: "smooth" };
        },
        routes,
    });

    router.beforeEach((to, from, next) => {
        if (to.fullPath.substr(0, 2) !== "/#") {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 10);
        }
        if (to.meta.title) {
            document.title = `${to.meta.title}`;
            document.getElementsByTagName("meta")["description"].content =
                to.meta.description;
            document.getElementsByTagName("meta")["keywords"].content =
                to.meta.keywords;
        } else {
            document.title = "One Stop Leading Software Solutions- Algoson Software";
            document.getElementsByTagName("meta")["description"].content =
                "Delivering Design and Development Mix to your Business Needs.Transforming Businesses with Powerful Software for 5+ years. You can call us on +917837588185 or email us on ms@algoson.com.";
            document.getElementsByTagName("meta")["keywords"].content =
                "Software solution company, customized software solution, web designing, Mobile Development, web development, machine learning, quality assurance, cloud computing, dev operation";
        }
        next();
    });
}

export default router;
